<template>
  <div class="p-survey">
    <div class="p-survey__wrap">
      <p class="p-survey__txt01" v-show="formStatus === 1">
        ご回答有難うございました。<br />
        またのご利用をお待ちしております。<br />
      </p>
      <p
        class="p-survey__txt01 red"
        v-html="storeMessage"
        v-show="formStatus === 1"
      ></p>
      <p class="p-survey__txt01" v-show="formStatus === 0">
        この度はご利用有難うございました。<br />
        アンケートにご協力をお願いいたします。
      </p>
      <p class="p-survey__txt01" v-show="formStatus === -1">
        ご回答有難うございました。<br />
        またのご利用をお待ちしております。
      </p>
      <form v-show="formStatus === 0" id="input-form">
        <div class="p-survey__row" id="sheetPoint">
          <div class="p-survey__txt02">
            100点満点中何点でしたか？<span class="red">*</span>
          </div>
          <select
            class="c-form__select p-survey__select"
            name="sheet_point"
            v-model="sheetPoint"
          >
            <option value=""></option>
            <option v-for="i in 13" :key="i" :value="(13 - i) * 10">
              {{ (13 - i) * 10 }}点
            </option>
          </select>
        </div>
        <div
          class="p-survey__row"
          v-for="(master_question, column_index) in masterQuestionList"
          v-bind:key="column_index"
          :id="master_question.id"
        >
          <div class="p-survey__txt02">
            {{ master_question.question_title
            }}<span class="red" v-show="master_question.required_flg == 1"
              >*</span
            >
          </div>
          <input
            v-if="master_question.category == 0"
            class="p-survey__input"
            type="text"
            :name="master_question.id"
            v-model="registerInput[column_index]['value']"
          />
          <textarea
            v-if="master_question.category == 1"
            class="p-survey__textarea"
            type="text"
            :name="master_question.id"
            v-model="registerInput[column_index]['value']"
          ></textarea>
          <select
            v-if="master_question.category == 2"
            class="c-form__select p-survey__select"
            :name="master_question.id"
            v-model="registerInput[column_index]['value']"
          >
            <option value=""></option>
            <option
              v-for="(detail, detail_index) in getQuestionDetail(
                master_question.id
              )"
              v-bind:key="detail_index"
              :value="detail.question_text"
            >
              {{ detail.question_text }}
            </option>
          </select>
          <div v-if="master_question.category == 3" class="p-survey__row02">
            <div
              class="c-form__check p-survey__check"
              v-for="(detail, detail_index) in getQuestionDetail(
                master_question.id
              )"
              v-bind:key="detail_index"
            >
              <input
                type="checkbox"
                :name="detail.id"
                :id="detail.id"
                :value="detail.id"
                v-on:change="
                  checkQuestion(
                    column_index,
                    detail.id,
                    detail.question_text,
                    detail.question_sort
                  )
                "
              />
              <label :for="detail.id">{{ detail.question_text }}</label>
            </div>
          </div>
        </div>
        <div class="p-survey__error">
          {{ errorMessage }}
        </div>
        <div class="p-survey__button" v-on:click="registerBtn()">
          回答を送信
        </div>
      </form>
    </div>
    <div class="p-survey__loading" :class="{ active: loadingFlg }">
      <div class="p-survey__loading_inner">
        <div class="p-survey__loading_txt01">Ultra Group</div>
        <div class="p-survey__loading_img01">
          <img class="u-image-w" src="../../assets/image/loading.gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Form",
  data: function () {
    return {
      formStatus: "",
      storeId: "",
      orderId: "",
      loadingFlg: true,
      registerInput: [],
      masterQuestionList: [],
      masterQuestionDetailList: [],
      errorMessage: "",
      storeMessage: "",
      sheetPoint: 0,
    };
  },
  created() {
    this.storeId = this.$route.query.s;
    this.orderId = this.$route.query.o;
    if (!this.orderId) {
      this.orderId = this.subdomain();
    }
    this.getListAppsync();
  },
  methods: {
    subdomain() {
      const hostname = window.location.hostname;
      const parts = hostname.split(".");
      if (parts.length > 2) {
        if (parts[0] == "www") {
          return parts[1] == "form" ? "" : parts[1];
        } else {
          return parts[0] == "form" ? "" : parts[0];
        }
      } else {
        return "";
      }
    },
    setMasterData() {
      /* 質問マスタループ */
      for (
        let questionIndex = 0;
        questionIndex < this.masterQuestionList.length;
        questionIndex++
      ) {
        let question = this.masterQuestionList[questionIndex];
        let inputCheck = [];
        let inputValue = "";
        /* 質問回答セット */
        this.registerInput.push({
          id: question.id,
          title: question.question_title,
          question_num: question.question_num,
          category: question.category,
          value: inputValue,
          check: inputCheck,
        });
      }
      this.sheetPoint = "";
    },
    async getListAppsync() {
      await axios({
        /* url: `https://3iffwz4scb.execute-api.ap-northeast-1.amazonaws.com/getform?s=${
          this.storeId ? this.storeId : ""
        }&o=${this.orderId ? this.orderId : ""}`, */
        url: `https://87gk7oenm1.execute-api.ap-northeast-1.amazonaws.com/getform?s=${
          this.storeId ? this.storeId : ""
        }&o=${this.orderId ? this.orderId : ""}`,
        /* url: `https://58nwi7er21.execute-api.ap-northeast-1.amazonaws.com/getform?s=${
          this.storeId ? this.storeId : ""
        }&o=${this.orderId ? this.orderId : ""}`, */
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.storeId = response.data.storeId;
          this.orderId = response.data.orderId;
          this.masterQuestionList = response.data.workSheet;
          this.masterQuestionDetailList = response.data.workSheetDetails;
          this.formStatus = response.data.formStatus;
          this.setMasterData();
          this.loadingFlg = false;
        })
        .catch((error) => {
          this.loadingFlg = false;
          this.errorMessage = error;
          throw new Error(error);
        });
      return;
    },
    /* 質問の詳細を取得 */
    getQuestionDetail(id) {
      let detail = this.masterQuestionDetailList.filter((item) => {
        if (item.question_id == id) {
          return item;
        }
      });
      return detail;
    },
    /**
     * 質問のチェック処理
     * @param index インデックス
     * @param id ID
     * @param value 値
     * @param sort 並び順
     */
    checkQuestion(index, id, value, sort) {
      if (document.getElementById(id).checked == true) {
        this.registerInput[index]["check"].push({
          question_detail_id: id,
          question_value: value,
          sort: sort,
        });
      } else {
        /* 配列から削除 */
        this.registerInput[index]["check"].splice(
          this.registerInput[index]["check"].findIndex((item) => item.id == id),
          1
        );
      }
    },
    /**
     * 注文情報内容確認
     * @return
     */
    async validationWorkSheet() {
      let validationFlg = false;
      this.removeErrorClass();
      this.errorMessage = "";
      if (this.sheetPoint !== 0 && !this.sheetPoint) {
        this.errorMessage = "「100点満点中何点でしたか？」";
        document.getElementById("sheetPoint").classList.add("error");
        validationFlg = true;
      }
      for (
        let masterIndex = 0;
        masterIndex < this.masterQuestionList.length;
        masterIndex++
      ) {
        let master_question = this.masterQuestionList[masterIndex];
        if (master_question.required_flg == 1) {
          if (this.registerInput[masterIndex]["category"] == 3) {
            let check = this.registerInput[masterIndex]["check"];
            if (check.length == 0) {
              this.errorMessage =
                this.errorMessage +
                "「" +
                this.masterQuestionList[masterIndex]["question_title"] +
                "」";
              validationFlg = true;
              document
                .getElementById(this.masterQuestionList[masterIndex]["id"])
                .classList.add("error");
            }
          } else {
            if (!this.registerInput[masterIndex]["value"]) {
              this.errorMessage =
                this.errorMessage +
                "「" +
                this.masterQuestionList[masterIndex]["question_title"] +
                "」";
              validationFlg = true;
              document
                .getElementById(this.masterQuestionList[masterIndex]["id"])
                .classList.add("error");
            }
          }
        }
      }
      if (validationFlg == true) {
        this.errorMessage = "必須項目のご入力をお願いいたします。";
      }
      return validationFlg;
    },
    /**
     * アンケート登録
     * @return
     */
    async registerBtn() {
      let validation = await this.validationWorkSheet();
      if (validation === false) {
        this.loadingFlg = true;
        await axios({
          /* url: `https://3iffwz4scb.execute-api.ap-northeast-1.amazonaws.com/create`, */
          /* 本番 */ url: `https://87gk7oenm1.execute-api.ap-northeast-1.amazonaws.com/create`,
          /* 予備 */ /* url: `https://58nwi7er21.execute-api.ap-northeast-1.amazonaws.com/create`, */
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            s: this.storeId,
            o: this.orderId,
            l: JSON.stringify(this.registerInput),
            p: this.sheetPoint,
          },
        })
          .then((response) => {
            this.formStatus = 1;
            if (this.sheetPoint < 85) {
              this.storeMessage =
                "女の子の対応が悪かった可能性があります。<br>お店の方で対応をするため、お電話差し上げる場合がございます。<br>宜しくお願い致します。";
            }
            this.loadingFlg = false;
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage =
              "送信中に何らかのエラーが発生いたしました。\nお手数をお掛けし申し訳ございませんが、お店までご連絡をお願いいたします。";
            this.loadingFlg = false;
            throw new Error(error);
          });
        this.loadingFlg = false;
      }
    },
    removeErrorClass() {
      const elements = this.$el.querySelectorAll(".p-survey__row");
      elements.forEach((el) => {
        if (el.classList.contains("error")) {
          el.classList.remove("error");
        }
      });
      if (document.getElementById("sheetPoint").classList.contains("error")) {
        document.getElementById("sheetPoint").classList.remove("error");
      }
    },
  },
};
</script>
